import React, { useState, useEffect } from "react";
import styles from "./Invoice.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toCommas } from "../../utils/utils";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { initialState } from "../../initialState";
import currencies from "../../currencies.json";
import {
  createInvoice,
  getInvoice,
  overDuesInvoice,
  updateInvoice,
} from "../../actions/invoiceActions";
import { getClientsByUser } from "../../actions/clientActions";
import AddClient from "./AddClient";
import InvoiceType from "./InvoiceType";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const Invoice = () => {
  const location = useLocation();
  const [invoiceId, setInvoiceId] = useState(null);
  const [openSnackbar] = useSnackbar();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [rates, setRates] = useState(18);
  const [hsn, setHsn] = useState(995419);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState(currencies[0].value);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  
  const [selectedDate, setSelectedDate] = useState(
    moment().endOf('month').valueOf()
  );
  const [client, setClient] = useState(null);
  const [type, setType] = useState("Proforma");
  const [status, setStatus] = useState("");
  const { id } = useParams();
  const clients = useSelector((state) => state.clients.clients);
  const { invoice } = useSelector((state) => state.invoices);

  const dispatch = useDispatch();
  const history = useNavigate();
  const user = JSON.parse(localStorage.getItem("profile"));

  

  useEffect(() => {
    getTotalCount();
  }, [location]);

  const getTotalCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v2/invoices/count?searchQuery=${user?.result?._id}`
      );

      console.log(response.data);

      const newInvoiceId = (Number(response.data) + 1)
        .toString()
        .padStart(3, "0");

      setInvoiceId(newInvoiceId);

      setInvoiceData({
        ...invoiceData,
        invoiceNumber: (Number(response.data) + 1).toString().padStart(3, "0"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getInvoice(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getClientsByUser({ search: user?.result._id }));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be update
      setInvoiceData(invoice);
      setRates(invoice.rates);
      setClient(invoice.client);
      setType(invoice.type);
      setStatus(invoice.status);
      if (location.pathname === `/edit/invoice/${invoice._id}`) {
        setSelectedDate(invoice.dueDate);
      }
    }
  }, [invoice, location]);

  useEffect(() => {
    if (type === "Receipt") {
      setStatus("Paid");
    } else {
      setStatus("Unpaid");
    }
  }, [type]);

  const defaultProps = {
    options: currencies,
    getOptionLabel: (option) => option.label,
  };

  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleHsn = (e) => {
    setHsn(e.target.value);
  };

  const handleRates = (e) => {
    setRates(e.target.value);

    setInvoiceData((prevState) => ({ ...prevState, tax: e.target.value }));
  };

  const handleChange = (index, e) => {
    const values = [...invoiceData.items];

    values[index][e.target.name] = e.target.value;

    setInvoiceData({ ...invoiceData, items: values });
  };

  useEffect(() => {
    //Get the subtotal
    const subTotal = () => {
      var arr = document.getElementsByName("amount");
      var subtotal = 0;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].value) {
          subtotal += +arr[i].value;
        }
        setSubTotal(parseFloat(subtotal.toFixed(2)));
      }
    };

    subTotal();
  }, [invoiceData]);

  useEffect(() => {
    const total = () => {
      //Tax rate is calculated as (input / 100 ) * subtotal + subtotal
      const overallSum = (rates / 100) * subTotal + subTotal;
      const tax = (rates / 100) * subTotal;
      setVat(parseFloat(tax.toFixed(2)));
      setTotal(parseFloat(overallSum.toFixed(0)));
    };
    total();
  }, [invoiceData, rates, subTotal, client]);

  const handleAddField = (e) => {
    e.preventDefault();
    setInvoiceData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          itemName: "",
          unitPrice: "",
          quantity: client?.flatarea || 0,
          overDues: 0,
          discount: 0,
          amount: "",
        },
      ],
    }));
  };

  const handleRemoveField = (index) => {
    const values = invoiceData.items;
    values.splice(index, 1);
    setInvoiceData((prevState) => ({ ...prevState, values }));
    // console.log(values)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // for client not null
    if (!client) {
      openSnackbar("Client field(s) cannot be empty.");
      return;
    }
    if (invoice) {
      if (location.pathname !== `/overdue/${invoice._id}`) {
        dispatch(
          updateInvoice(invoice._id, {
            ...invoiceData,
            subTotal: subTotal,
            total: total,
            vat: vat,
            hsn: hsn,
            rates: rates,
            currency: currency,
            dueDate: selectedDate,
            client,
            type: type,
            status: status,
          })
        );
        history(`/invoice/${invoice._id}`);
      } else {
        dispatch(
          overDuesInvoice({
            ...invoiceData,
            subTotal: subTotal,
            total: total,
            vat: vat,
            hsn: hsn,
            rates: rates,
            currency: currency,
            dueDate: selectedDate,
            invoiceNumber: `${
              invoiceId < 100
                ? Number(invoiceId).toString().padStart(3, "0")
                : Number(invoiceId).toString()
            }`,
            client,
            type: type,
            status: status,
          })
        );
      }
    } else {
      const nameEmpty = invoiceData.items.some((item) => item.itemName === "");
      const priceEmpty = invoiceData.items.some(
        (item) => item.unitPrice === ""
      );
      if (!priceEmpty) {
        if (!nameEmpty) {
          dispatch(
            createInvoice(
              {
                ...invoiceData,
                subTotal: subTotal,
                total: total,
                hsn: hsn,
                vat: vat,
                rates: rates,
                currency: currency,
                dueDate: selectedDate,
                createdAt: new Date().toISOString(),
                invoiceNumber: `${
                  invoiceData.invoiceNumber < 100
                    ? Number(invoiceData.invoiceNumber)
                        .toString()
                        .padStart(3, "0")
                    : Number(invoiceData.invoiceNumber)
                }`,
                client,
                type: type,
                status: status,
                paymentRecords: [],
                creator: [user?.result?._id || user?.result?.googleId],
              },
              openSnackbar,
              history
            )
          );
        } else {
          openSnackbar("Please Enter Item name");
        }
      } else {
        openSnackbar("Please Enter Item Price");
      }
    }

    //  setInvoiceData(initialState)
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };

  if (!user) {
    history("/login");
  }

  const isOverdues = location.pathname.includes("/overdue");

  return (
    <div
      className={styles.invoiceLayout}
      style={{
        border: "none",
        borderBottom: "1px solid rgb(184, 184, 184)",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        borderRadius: "12px",
      }}
    >
      <form onSubmit={handleSubmit} className="mu-form">
        <AddClient setOpen={setOpen} open={open} />
        <Container className={classes.headerContainer}>
          <br />
          <br />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Avatar
                alt="Logo"
                variant="square"
                src={user?.userProfile?.logo}
                className={classes.large}
              >
                {user?.userProfile?.businessName?.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item>
              <InvoiceType type={type} setType={setType} />
              Invoice #:
              <div
                style={{
                  marginTop: "15px",
                  width: "100px",
                  padding: "8px",
                  display: "inline-block",
                  backgroundColor: "#f4f4f4",
                  outline: "0px solid transparent",
                }}
                contenteditable="true"
                onInput={(e) =>
                  setInvoiceData({
                    ...invoiceData,
                    invoiceNumber: e.currentTarget.textContent,
                  })
                }
              >
                <span
                  style={{ width: "40px", color: "black", padding: "15px" }}
                  contenteditable="false"
                >
                  {/* {invoiceId} */}
                  {isOverdues ? invoiceId : invoiceData.invoiceNumber}
                  {/* {invoiceData.invoiceNumber} */}
                </span>
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "40px" }}
          >
            <Grid item style={{ width: "50%" }}>
              <Container>
                <Typography
                  variant="overline"
                  style={{ color: "gray", paddingRight: "3px" }}
                  gutterBottom
                >
                  Bill to
                </Typography>

                {client && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      Name:{client?.name}
                    </Typography>

                    <Typography variant="subtitle2">
                      Flat: {client?.flat}
                    </Typography>
                    <Typography variant="subtitle2">
                      Floor: {client?.floor}
                    </Typography>
                    <Typography variant="subtitle2">
                      Block: {client?.block}
                    </Typography>
                    <Typography variant="subtitle2">
                      Email:{client?.email}
                    </Typography>
                    <Typography variant="subtitle2">
                      Phone:{client?.phone}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      GST:{client?.gstno}
                    </Typography>

                    <Button
                      color="primary"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setClient(null)}
                    >
                      Change
                    </Button>
                  </>
                )}
                <div
                  style={client ? { display: "none" } : { display: "block" }}
                >
                  <Autocomplete
                    {...clientsProps}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={!invoice && true}
                        label="Select Customer"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    value={clients?.name}
                    onChange={(event, value) => setClient(value)}
                  />
                </div>
                {!client && (
                  <>
                    <Grid item style={{ paddingBottom: "10px" }}>
                      <Chip
                        avatar={<Avatar>+</Avatar>}
                        label="New Customer"
                        onClick={() => setOpen(true)}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
              </Container>
            </Grid>

            <Grid item style={{ marginRight: 20, textAlign: "right" }}>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Status
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: type === "Receipt" ? "green" : "red" }}
              >
                {type === "Receipt" ? "Paid" : "Unpaid"}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Date
              </Typography>

              <Typography variant="body2" gutterBottom>
                {moment().format("ll")}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Due Date
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedDate
                  ? moment(selectedDate).format("ll")
                  : moment().format("ll")}
              </Typography>
              <Typography variant="overline" gutterBottom>
                Amount
              </Typography>
              <Typography variant="h6" gutterBottom>
                {currency} {toCommas(total)}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <div>
          <TableContainer component={Paper} className="tb-container">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Area (Sq.ft)</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Disc(%)</TableCell>
                  <TableCell>Late Fee(2% P.M)</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData.items.map((itemField, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row" style={{ width: "35%" }}>
                      {" "}
                      <InputBase
                        style={{ width: "100%" }}
                        outline="none"
                        sx={{ ml: 1, flex: 1 }}
                        type="text"
                        name="itemName"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.itemName}
                        placeholder="Item name or description"
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="quantity"
                        onChange={(e) => handleChange(index, e)}
                        value={(itemField.quantity = client?.flatarea)}
                        placeholder="0"
                        disabled
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="unitPrice"
                        onChange={(e) => handleChange(index, e)}
                        value={
                          (itemField.unitPrice = client?.price
                            ? client?.price
                            : itemField.unitPrice)
                        }
                        placeholder="0"
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="discount"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.discount}
                        placeholder="0"
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="overDues"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.overDues}
                        placeholder="0"
                      />{" "}
                    </TableCell>

                    <TableCell align="right">
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="amount"
                        onChange={(e) => handleChange(index, e)}
                        value={(
                          client?.flatarea * itemField.unitPrice +
                          (client?.flatarea *
                            itemField.unitPrice *
                            (itemField.overDues ? itemField.overDues : 0)) /
                            100 -
                          (client?.flatarea *
                            itemField.unitPrice *
                            itemField.discount) /
                            100
                        ).toFixed(2)}
                        placeholder="00"
                        disabled
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleRemoveField(index)}>
                        <DeleteOutlineRoundedIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.addButton}>
            <button onClick={handleAddField}>+</button>
          </div>
        </div>

        <div className={styles.invoiceSummary}>
          <div className={styles.summary}>Invoice Summary</div>
          <div className={styles.summaryItem}>
            <p>Sub total:</p>
            <h4>{subTotal}</h4>
          </div>
          <div className={styles.summaryItem}>
            <p>GST(%):</p>
            <h4>{vat}</h4>
          </div>
          <div className={styles.summaryItem}>
            <p>Total</p>
            <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>
              {currency} {toCommas(total)}
            </h4>
          </div>
        </div>

        <div className={styles.toolBar}>
          <Container>
            <Grid container>
              <Grid item style={{ marginTop: "16px", marginRight: 10 }}>
                <TextField
                  type="number"
                  step="any"
                  name="rates"
                  id="rates"
                  value={rates}
                  onChange={handleRates}
                  placeholder="e.g 10"
                  label="GST(%)"
                />
              </Grid>

              <Grid item style={{ marginTop: "16px", marginRight: 10 }}>
                <TextField
                  type="number"
                  step="any"
                  name="hsn"
                  id="hsn"
                  value={hsn}
                  onChange={handleHsn}
                  placeholder="e.g 12345"
                  label="HSN Number"
                />
              </Grid>
              <Grid item style={{ marginRight: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Due date"
                    format="MMM dd,yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ width: 270, marginRight: 10 }}>
                <Autocomplete
                  {...defaultProps}
                  id="debug"
                  debug
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select currency"
                      margin="normal"
                    />
                  )}
                  value={currency.value}
                  onChange={(event, value) => setCurrency(value.value)}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={styles.note}>
          <h4>Note|Payment Info</h4>
          <textarea
            style={{ border: "solid 1px #d6d6d6", padding: "10px" }}
            placeholder="Provide additional details or terms of service"
            onChange={(e) =>
              setInvoiceData({ ...invoiceData, notes: e.target.value })
            }
            value={invoiceData.notes}
          />
        </div>

        {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        <Grid container justifyContent="center">
          {location.pathname.includes("/overdue") ? (
            <Button
              variant="contained"
              style={{ justifyContentContent: "center" }}
              type="submit"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Create with Fine
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{ justifyContentContent: "center" }}
              type="submit"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Continue & Save
            </Button>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default Invoice;
