import React, { useEffect, useState } from "react";
import { toCommas } from "../../utils/utils";
import styles from "./Dashboard.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInvoicesByUser } from "../../actions/invoiceActions";
import Empty from "../svgIcons/Empty";
import Chart from "./Chart";
import Donut from "./Donut";

import { utils, writeFile } from "xlsx";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import PaymentIcon from "@material-ui/icons/Payment";
import HourglassEmptyIcon from "@material-ui/icons/Schedule";
import AttachMoneyIcon from "@material-ui/icons/MonetizationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/DoneAll";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ExcelIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import Spinner from "../Spinner/Spinner";
import { CLEAR_INVOICES } from "../../actions/constants";

const Dashboard = () => {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showChildButtons, setShowChildButtons] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const { invoices, isLoading } = useSelector((state) => state?.invoices);

  const invoiceData = invoices?.data || [];

  const overDue = invoiceData?.filter(
    (invoice) =>
      (invoice.dueDate <= new Date().toISOString() &&
        invoice.status === "Unpaid") ||
      invoice.status === "Partial"
  );

  let paymentHistory = [];

  for (let i = 0; i < invoiceData.length; i++) {
    if (invoiceData[i].paymentRecords !== undefined) {
      let records = invoiceData[i].paymentRecords.map((record) => ({
        ...record,
        invoiceNumber: invoiceData[i].invoiceNumber,
        id: invoiceData[i]._id,
      }));
      paymentHistory = paymentHistory.concat(records);
    }
  }

  const sortHistoryByDate = paymentHistory.sort(function (a, b) {
    const dateA = moment(a.datePaid);
    const dateB = moment(b.datePaid);
    return dateB - dateA;
  });

  let totalPaid = 0;
  for (let i = 0; i < invoiceData.length; i++) {
    if (invoiceData[i].totalAmountReceived !== undefined) {
      totalPaid += invoiceData[i].totalAmountReceived;
    }
  }

  let totalAmount = 0;
  for (let i = 0; i < invoiceData.length; i++) {
    totalAmount += invoiceData[i].total;
  }

  const handleExportClick = () => {
    setShowChildButtons(true);
  };

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setOpen(false);
  };

  const exportToExcel = (data) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Payment History");
    writeFile(
      workbook,
      `Paysheet-${
        user?.userProfile?.businessName
          ? user?.userProfile?.businessName
          : user?.userProfile?.name
      }.xlsx`
    );
    setShowChildButtons(false);
  };
  const prepareDataForExport = (paymentHistory) => {
    return paymentHistory.map((record) => ({
      "Invoice No.": record.invoiceNumber,
      Client: record.paidBy,
      "Paid At": moment(record.datePaid).format("ll"),
      "Amount Paid": record.amountPaid,
      "Payment Method": record.paymentMethod,
      "Payment Note": record.note,
    }));
  };

  useEffect(() => {
    dispatch({ type: CLEAR_INVOICES });
    dispatch(
      getInvoicesByUser({ search: user?.result._id || user?.result?.googleId })
    );
    // eslint-disable-next-line
  }, [location, dispatch]);

  const unpaidInvoice = invoiceData?.filter(
    (invoice) => invoice.status === "Unpaid"
  );
  const paid = invoiceData?.filter((invoice) => invoice.status === "Paid");
  const partial = invoiceData?.filter(
    (invoice) => invoice.status === "Partial"
  );

  if (!user) {
    history("/login");
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (invoiceData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        {/* <Spinner /> */}
        <Empty />
        <p style={{ padding: "40px", color: "gray" }}>
          Nothing to display. Click the plus icon to start creating
        </p>
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <section
        className={styles.stat}
        style={{
          backgroundColor: "white",
          textAlign: "center",
          width: "90%",
          margin: "10px auto",
          padding: "10px",
          borderBottom: "1px solid rgb(184, 184, 184)",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          borderRadius: "12px",
        }}
      >
        <h1 style={{ textAlign: "center", padding: "30px" }}>Dashboard</h1>
        <ul className={styles.autoGrid}>
          <li
            className={styles.listItem}
            style={{ backgroundColor: "#34A853", color: "white" }}
          >
            <div>
              <p>{toCommas(totalPaid.toFixed(2))}</p>
              <h2 style={{ color: "white" }}>Payment Received</h2>
            </div>
            <div>
              <PaymentIcon />
            </div>
          </li>

          <li
            className={styles.listItem}
            style={{ backgroundColor: "#FBBC05", color: "white" }}
          >
            <div>
              <p>{toCommas((totalAmount - totalPaid).toFixed(2))}</p>
              <h2 style={{ color: "white" }}>Pending Amount</h2>
            </div>
            <div>
              <HourglassEmptyIcon />
            </div>
          </li>

          <li
            className={styles.listItem}
            style={{ backgroundColor: "#4285F4", color: "white" }}
          >
            <div>
              <p>{toCommas(totalAmount.toFixed(2))}</p>
              <h2 style={{ color: "white" }}>Total Amount</h2>
            </div>

            <div>
              <AttachMoneyIcon />
            </div>
          </li>
          <Link to="/total" style={{ textDecoration: "none" }}>
            <li
              className={styles.listItem}
              style={{ backgroundColor: "#4285F4", color: "white" }}
            >
              <div>
                <p>{invoiceData.length}</p>
                <h2 style={{ color: "white" }}>Total Invoices</h2>
              </div>
              <div>
                <DescriptionIcon />
              </div>
            </li>
          </Link>
          <Link to="/paid" style={{ textDecoration: "none" }}>
            <li
              className={styles.listItem}
              style={{ backgroundColor: "#34A853", color: "white" }}
            >
              <div>
                <p>{paid.length}</p>
                <h2 style={{ color: "white" }}>Paid Invoices</h2>
              </div>
              <div>
                <DoneIcon />
              </div>
            </li>
          </Link>

          <Link to="/partial" style={{ textDecoration: "none" }}>
            <li
              className={styles.listItem}
              style={{ backgroundColor: "#FBBC05", color: "white" }}
            >
              <div>
                <p>{partial.length}</p>
                <h2 style={{ color: "white" }}>Partially Invoices</h2>
              </div>
              <div>
                <MoneyOffIcon />
              </div>
            </li>
          </Link>

          <Link to="/unpaid" style={{ textDecoration: "none" }}>
            <li
              className={styles.listItem}
              style={{ backgroundColor: "#EA4335", color: "white" }}
            >
              <div>
                <p>{unpaidInvoice.length}</p>
                <h2 style={{ color: "white" }}>Unpaid Invoices</h2>
              </div>
              <div>
                <HighlightOffIcon />
              </div>
            </li>
          </Link>

          <Link to="/overdues" style={{ textDecoration: "none" }}>
            <li
              className={styles.listItem}
              style={{ backgroundColor: "#EA4335", color: "white" }}
            >
              <div>
                <p>{overDue.length}</p>
                <h2 style={{ color: "white" }}>Overdue</h2>
              </div>
              <div>
                <WarningIcon />
              </div>
            </li>
          </Link>
        </ul>
      </section>
      <br />
      <br />

      {paymentHistory.length !== 0 && (
        <>
          <section
            style={{
              backgroundColor: "white",
              textAlign: "center",
              width: "90%",
              margin: "10px auto",
              padding: "10px",
              borderBottom: "1px solid rgb(184, 184, 184)",
              boxShadow:
                "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
              borderRadius: "12px",
            }}
          >
            <h1 style={{ textAlign: "center", padding: "30px" }}>
              Invoices Chart
            </h1>
            <Donut unpaid={unpaidInvoice} paid={paid} partial={partial} />
          </section>
          <br />
          <br />
          <section>
            <Chart paymentHistory={paymentHistory} />
          </section>
        </>
      )}
      <br />
      <br />
      <div
        style={{
          backgroundColor: "white",
          textAlign: "center",
          width: "90%",
          margin: "10px auto",
          borderBottom: "1px solid rgb(184, 184, 184)",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          borderRadius: "12px",
        }}
      >
        <section>
          <h1 style={{ textAlign: "center", paddingTop: "30px" }}>
            {paymentHistory.length
              ? "Recent Payments"
              : "No payment received yet"}
          </h1>

          {!showChildButtons && (
            // <Button
            //   onClick={handleExportClick}
            //   style={{
            //     textAlign: "center",
            //     padding: "10px",
            //     marginTop: "10px",
            //     marginBottom: "10px",
            //     background: "#4285F4",
            //     color: "white",
            //   }}
            // >
            //   <ExcelIcon style={{ marginRight: "8px" }} />
            //   Export to Excel
            // </Button>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                Select Month
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select a Month</DialogTitle>
                <DialogContent>
                  <DatePicker
                    views={["year", "month"]}
                    label="Year and Month"
                    minDate={new Date("2000-01-01")}
                    maxDate={new Date("2030-12-31")}
                    value={selectedDate}
                    onChange={handleDateChange}
                    TextFieldComponent={(props) => <TextField {...props} />}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </MuiPickersUtilsProvider>
          )}

          {showChildButtons && (
            <div>
              <Button
                onClick={() =>
                  exportToExcel(
                    prepareDataForExport(
                      sortHistoryByDate.filter((record) =>
                        moment(record.datePaid).isSame(moment(), "month")
                      )
                    )
                  )
                }
                style={{
                  textAlign: "center",
                  padding: "10px",
                  margin: "10px",
                  background: "#4285F4",
                  color: "white",
                }}
              >
                <ExcelIcon style={{ marginRight: "8px" }} />
                Export {moment().format("MMMM")} Data
              </Button>
              <Button
                onClick={() =>
                  exportToExcel(
                    prepareDataForExport(
                      sortHistoryByDate.filter((record) =>
                        moment(record.datePaid).isSame(
                          moment().subtract(1, "months"),
                          "month"
                        )
                      )
                    )
                  )
                }
                style={{
                  textAlign: "center",
                  padding: "10px",
                  margin: "10px",
                  background: "#4285F4",
                  color: "white",
                }}
              >
                <ExcelIcon style={{ marginRight: "8px" }} />
                Export {moment().subtract(1, "months").format("MMMM")} Data
              </Button>
            </div>
          )}

          <div>
            <div className={styles.table}>
              <table>
                <tbody>
                  {paymentHistory.length !== 0 && (
                    <tr>
                      <th style={{ padding: "15px", textAlign: "center" }}>
                        Invoice No.
                      </th>
                      <th style={{ padding: "15px", textAlign: "center" }}>
                        Client
                      </th>
                      <th style={{ padding: "15px", textAlign: "center" }}>
                        PaidAt
                      </th>
                      <th style={{ padding: "15px", textAlign: "center" }}>
                        Amount Paid
                      </th>
                      <th style={{ padding: "15px", textAlign: "center" }}>
                        Payment Method
                      </th>
                      <th style={{ padding: "15px", textAlign: "center" }}>
                        Payment Note
                      </th>
                    </tr>
                  )}

                  {sortHistoryByDate.map((record) => (
                    <tr
                      className={styles.tableRow}
                      key={record._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => history(`/invoice/${record.id}`)}
                    >
                      <td>{record.invoiceNumber}</td>
                      <td>{record.paidBy}</td>
                      <td>{moment(record.datePaid).format("lll")}</td>
                      <td>
                        <h3 style={{ color: "#00A86B", fontSize: "14px" }}>
                          {toCommas(record.amountPaid)}
                        </h3>
                      </td>
                      <td>{record.paymentMethod}</td>
                      <td>{record.note}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
